import axios from 'axios';
import { ip, tenant_id } from '@/config/index';
import storage from '@/utils/sessionStorge';

// 1. 创建axios实例,配置基础路径
const http = axios.create({
	baseURL: ip,
	timeout: 10000 // 连接超时
});

// 这些接口不需要tenant-id请求头
const ignoreTenantIdUrlList = [
	'/app-api/system/dept/rootPage',
	'/app-api/system/user/doctorPage',
	'/app-api/prod/mall-product/productInner',
	'/app-api/system/auth/getMpAuthUrl',
	'/app-api/customer/dept-stay/getChosenByOpenid',
	'/app-api/customer/dept-stay/getStayDeptList',
	'/app-api/customer/dept-stay/getStayTenantList',
	'/app-api/system/auth/create-jsapi-signature',
	// '/admin-api/infra/file/upload',
	'/app-api/customer/agreement/getByType',
	'/app-api/system/app-version/getNew',
	'/admin-api/system/video/list',
	'/app-api/system/auth/bindUserUnionid',
	'/app-api/customer/detect-chart/details'
	// '/app-api/customer/dept-stay/chooseDeptStay'
];

// 固定TenantId
const lockTenantId = [
	'/app-api/system/competition-final-scoring/scoring',
	'/app-api/system/competition-final-judge/getAllList',
	'/app-api/system/competition-final-scoring/listBy'
];

// 这些接口不需要token请求头
const ignoreTokenUrlList = [
	'/app-api/system/dept/rootPage',
	'/app-api/system/dept/leafPage',
	'/app-api/system/auth/send-sms-code',
	'/app-api/mp/customer/createCustomer',
	'/app-api/system/auth/bindUserUnionid',

	'/app-api/prod/mall-product/productInner',
	'/app-api/system/auth/wxMpAuthToken',
	'/app-api/system/auth/getMpAuthUrl',
	'/app-api/customer/dept-stay/getChosenByOpenid',
	'/app-api/customer/dept-stay/getStayDeptList',
	'/app-api/customer/dept-stay/getStayTenantList',
	'/app-api/system/auth/create-jsapi-signature',
	'/app-api/customer/dept-stay/chooseDeptStay',
	// '/admin-api/infra/file/upload',
	'/app-api/customer/agreement/getByType',
	'/app-api/system/app-version/getNew',
	'/admin-api/system/video/list',
	'/app-api/customer/detect-chart/details',
	'/app-api/system/auth/competitionSmsLogin',
	'/app-api/market/user/create',
	'/app-api/market/exhibitionQrcode/active'
];

// 请求头
const setHeader = ['/app-api/infra/file/upload'];

// 2. 定义请求拦截器:给所有请求都带上token
http.interceptors.request.use(
	(req) => {
		const noNeddTenantId = ignoreTenantIdUrlList.some((item) => req.url.includes(item));
		const isLockTenantId = lockTenantId.some((item) => req.url.includes(item));

		const noNeddToken = ignoreTokenUrlList.some((item) => req.url.includes(item));

		const userSession = storage.getItem('user');

		if (setHeader.includes(req.url)) {
			req.headers['content-type'] = 'multipart/form-data';
		}
		if (!noNeddTenantId) {
			req.headers['tenant-id'] = userSession?.userInfo?.tenantId;
		}
		if (isLockTenantId) {
			req.headers['tenant-id'] = tenant_id;
		}
		if (!noNeddToken) {
			if (!userSession?.userInfo.accessToken) {
				req.headers['Authorization'] = 'Bearer ';
			} else {
				req.headers['Authorization'] = `Bearer ${userSession.userInfo.accessToken}`;
			}
		}
		if (
			req.url === '/app-api/system/auth/userSmsLogin' ||
			req.url === '/app-api/market/user/create' ||
			req.url === '/app-api/system/auth/send-sms-code' ||
			req.url === '/app-api/market/exhibition/create' ||
			req.url.includes('/app-api/market/exhibitionQrcode/active')
		) {
			req.headers['tenant-id'] = 1;
		}

		return req;
	},
	(
		error // 返回错误的信息
	) =>
		// eslint-disable-next-line implicit-arrow-linebreak
		Promise.reject(error)
);

// 3. 定义响应拦截器: 对服务器响应客户端的数据进行统一处理
http.interceptors.response.use(
	(res) => {
		// 3.1 对响应数据进行处理
		const { data } = res;
		const { code } = data;
		if (code === 401) {
			return;
			window.location.hash = '#/category';

			// eslint-disable-next-line prefer-promise-reject-errors
			return Promise.reject({ ...data, msg: '登录信息过期，请重新登录' });
		}
		if (code === 0) {
			// 请求成功时,将res.data对象返回给响应对象
			return data;
		}
		return Promise.reject(data);
	},
	(error) => Promise.reject(error)
);

// 4. 导出axios实例,供组件异步请求使用
export default http;
